const GreenvilleData = [
	{
		name: 'Kitamura HX400',
		documents: [
			{
				type: 'Ledger',
				description: 'Kitamura HX400 Machine Ledger',
				link: 'https://docs.google.com/spreadsheets/d/15NkhfVDpfNMaltvqAeT08iG1jeRS1L_0Nx7Om9kQZMU',
			},
			{
				type: 'SMP',
				description: 'SMP-0001 - REPLACEMENT OF MITSUBISHI M700/750 FUSE',
				link: 'https://docs.google.com/spreadsheets/d/1wOCpRNBcYQ5KWDcW5l-7SGBZGogGb_lBBz5brdWxT7Q',
			},
			{
				type: 'SMP',
				description: 'SMP-0002 - Replacement of SMC ISE20 Digital Pressure Switch',
				link: 'https://docs.google.com/spreadsheets/d/13BGdy7i5FdqGCImhoT685SU2G2RzNfjutNCj27hRysw',
			},
			{
				type: 'SMP',
				description: 'SMP-0003 - Replacement of SMC SY5000 Solenoid Valve',
				link: 'https://docs.google.com/spreadsheets/d/1YY3U3QDT1x9JDt9XUsf8P_82k8vcmcPiKHNMON5MVNY',
			},
			{
				type: 'SMP',
				description: 'SMP-0004 - Replacement of Water Separator Filter / Gasket / O-Ring',
				link: 'https://docs.google.com/spreadsheets/d/1kSSR2PUg3iS3XMyMa5FipzR814IDkBvQF3VrO5dO95E',
			},
			{
				type: 'SMP',
				description: 'SMP-0005 - Filling Daikin Hydraulic Unit',
				link: 'https://docs.google.com/spreadsheets/d/1esCkDBMSd_iiR140JuohWf48OGQG6PAQBQN1b_Y0Q60',
			},
			{
				type: 'SMP',
				description: 'SMP-0006 - Replacement of SMC ISE20B Hydraulic Pressure Switch',
				link: 'https://docs.google.com/spreadsheets/d/1bP2hbDgBpLwnU9oxfKYprYaPun_bik_JWjBFqcwf29k',
			},
			{
				type: 'SMP',
				description: 'SMP-0007 - Cleaning Core / DC Fan of Dakin Hydraulic Unit',
				link: 'https://docs.google.com/spreadsheets/d/1VHnnLIB48NresNNWsBVSpt3Tlk1QJV8sx7SemZcV9PI',
			},
			{
				type: 'SMP',
				description: 'SMP-0008 - Replacement and Cleaning of Suction Strainer',
				link: 'https://docs.google.com/spreadsheets/d/1DrGaZtW1Rb0PMzzlmVlJGErbvxrUDEFcc_VmXHfglQ0',
			},
			{
				type: 'SMP',
				description: 'SMP-0009 - Oil Change / Fill  LUBE CORPORATION AMO-II-150S',
				link: 'https://docs.google.com/spreadsheets/d/1M4XUaT5FHBrRNvGLSyvLSncXC5ZZ3I3i3wR19DhSwYw',
			},
			{
				type: 'SMP',
				description: 'SMP-0010 - Oil Change / Fill  DAIDO MRJ010211A-KT001',
				link: 'https://docs.google.com/spreadsheets/d/1bZH8AS9SxjS5DP_MhYLow5LhQF-I8G87v12DfR5HPws',
			},
			{
				type: 'SMP',
				description: 'SMP-0011 - X-AXIS ASSEMBLY - Motor and Coupling Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1GW98ZA-H-RVEOYZiMpqWUGYCp4NK-kURVH4B96wDrv8',
			},
			{
				type: 'SMP',
				description: 'SMP-0012 - Y-AXIS ASSEMBLY - Motor and Coupling Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1WgvsRh9_o-k2Js4hNxlVuaNNkXcAz9QfqqyqUnFrYek',
			},
			{
				type: 'SMP',
				description: 'SMP-0013 - Z-AXIS ASSEMBLY - Motor Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1nHBjrfGc2EGbVSP0dt9YkWGk4LRxCnvbA3gj0V6riZ0',
			},
			{
				type: 'SMP',
				description: 'SMP-0014 - B-AXIS TABLE - Wiper Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1m8Ea9aOW4wErIQhVdXmpX2saUljGR4EKArWbldAlHDE',
			},
			{
				type: 'SMP',
				description: 'SMP-0015 - B-AXIS TABLE - PALLET TABLE - BLOCK  Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1EV5IVZDmzZRAYN8h_m5d_LWW2-mbtW5c0abP4e22tY8',
			},
			{
				type: 'SMP',
				description: 'SMP-0016 - ATC MAGAZINE -  Motor Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1nkrm_5go9Z_SPOqH4lGDGJCxb9QzCLQ-YPkS5el5J2s',
			},
			{
				type: 'SMP',
				description: 'SMP-0017 - ATC ASSEMBLY - Motor Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1FW-9HQ4SrkZEImKBnMrggoU0o4UMUMu_Qz9Mf2Uc-4Q',
			},
			{
				type: 'SMP',
				description: 'SMP-0018 - PALLET CHANGER - GEAR Motor Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1OmBmP7RTaC-BKZzkQZhDzqPwcvJgiGqHzeMlaQUjhiY',
			},
			{
				type: 'SMP',
				description: 'SMP-0019 - ATC DOOR ASSEMBLY - Motor Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1DIownXiSP8JYZC2_huB4IFD8KLG75t4F2FbZxznzAis',
			},

			{
				type: 'SMP',
				description: 'SMP-0020 - CHIP CONVEYOR - MOTOR REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1ZtWW3i6GhHhN4VZOjGFWaBomqWxfL5Tc300JQlpNaI4',
			},
			{
				type: 'SMP',
				description: 'SMP-0021 - X-AXIS HEIDENHAIN SCALE & READER REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1GYS7_3nIDMWiuU69wyHJ3ODvEvROxASR86lz3V8ZyDM',
			},
			{
				type: 'SMP',
				description: 'SMP-0022 - Y-AXIS HEIDENHAIN SCALE & READER REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1KAxatUL8PYKw1qTbk6VX4gTwCvqQt9eGFCTXrBoS1L8',
			},
			{
				type: 'SMP',
				description: 'SMP-0023 - Z-AXIS HEIDENHAIN SCALE & READER REPLACEMENT',
				link: 'https://docs.google.com/spreadsheets/d/1CbBjcyiLiK1Cosk5DZkeDW7Pq2pqscxbWQtUT8QoO0U',
			},
		],
	},
];

export default GreenvilleData;