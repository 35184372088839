// packages
import { Main, Container, Header, Footer } from '../../components/layout';
import { HomeButton } from '../../components/utilities';
import styled from 'styled-components';

import homebg from '../assets/home-bg.jpg';
import clyde from '../assets/clyde-oh.jpg';
import findlay from '../assets/findlay-oh.jpg';
import marion from '../assets/marion-oh.jpg';
import ottawa from '../assets/ottawa-oh.jpg';
import cleveland from '../assets/cleveland-tn.jpg';
import tulsa from '../assets/tulsa-ok.jpg';
import greenville from '../assets/greenville-oh.jpg';

const HomeComp = ({ className }) => {
	return (
		<>
			<Main className={className}>
				<Header />

				<Container>
					<mask style={{ backgroundImage: `url(${homebg})` }}></mask>
					<section class="home-btns">
						<HomeButton title="CLYDE, OH" desc="Internal & External" img={clyde} link="/clyde" />
						<HomeButton title="FINDLAY, OH" desc="Operation and Maintenance Manuals" img={findlay} link="/findlay" />
						<HomeButton title="MARION, OH" desc="Operation and Maintenance" img={marion} link="/marion" />
						<HomeButton title="OTTAWA, OH" desc="Internal & External" img={ottawa} link="/ottawa" />
						<HomeButton title="CLEVELAND, TN" desc="Operation and Maintenance Manuals" img={cleveland} link="/cleveland" />
						<HomeButton title="TULSA, OK" desc="Operation and Maintenance" img={tulsa} link="/tulsa" />
						<HomeButton title="GREENVILLE, OH" desc="Operation and Maintenance" img={greenville} link="/greenville" />
					</section>
				</Container>
			</Main>
			<Footer />
		</>
	);
};

const Home = styled(HomeComp)`
	.home-btns {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		position: absolute;
		width: 100%;
		top: 4vh;
		margin: auto;
	}

	@media screen and (max-width: 480px) {
		width: 100vw;
		.home-btns {
			display: block;
			top: 1vh;
			height: auto;
		}
	}
`;

export default Home;
