const FindlayData = [
	{
		name: 'Battenfeld Press 2',
		documents: [
			{
				description: 'Battenfeld Press 2',
				type: 'Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1ofRYf5oPQSyoJ5DHfEXK8ElERpxOPuUebKyHU_3cBw4',
			},
		],
	},
	{
		name: 'Mesh Welder GAE812',
		documents: [
			{
				description: 'Mesh Welder GAE812',
				type: 'Ledger',
				link: 'https://docs.google.com/spreadsheets/d/16imIiWu7982KTkexu_XRMgUnLt60HjlWrOupEbhFKHo',
			},
		],
	},
];

export default FindlayData;
