import React, { useState, useEffect } from 'react';
import GlobalStyles from './styles/GlobalStyles';
import { Main, Container, Header, Footer } from './components/layout';
import Router from './Router';
import 'tablesorter';
import 'tablesorter/dist/js/extras/jquery.tablesorter.pager.min.js';
import 'tablesorter/dist/css/theme.ice.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import homebg from './pages/assets/home-bg.jpg';

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(null); // Initialize to null
    const password = "whirlpoolwcm";

    useEffect(() => {
        const authStatus = localStorage.getItem('isAuthenticated');
        setIsAuthenticated(authStatus === 'true'); // Set to true or false
    },);

    const handleLogin = (enteredPassword) => {
        if (enteredPassword === password) {
            setIsAuthenticated(true);
            localStorage.setItem('isAuthenticated', 'true');
        } else {
            alert('Incorrect password.');
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('isAuthenticated');
        setIsAuthenticated(false);
    };

    if (isAuthenticated === null) { // Loading state
        return <div>Loading...</div>; // Or a loading spinner
    }

    if (!isAuthenticated) {
        return (
			<>
            <GlobalStyles />
			<Main style={{backgroundColor: 'white'}}>
				<Header />

				<Container style={{ backgroundImage: `url(${homebg})` }}>
					<div className="login-container">
						<div className="login-borders">
			                <LoginForm onLogin={handleLogin} />
							<p style={{marginTop: '1em'}}>Please contact your ITS Representative if you do not know your password.</p>
						</div>
            		</div>
				</Container>
			</Main>
			<Footer />
			</>
        );
    }

    return (
        <>
            <GlobalStyles />
            <Router />	
            <button className="logoff" onClick={handleLogout}>Logout</button>
        </>
    );
}

function LoginForm({ onLogin }) {
  const [enteredPassword, setEnteredPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onLogin(enteredPassword);
    setEnteredPassword(''); // Clear input field
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="password">Password:</label>
      <input
	  	className="login-input"
        type="password"
        id="password"
        value={enteredPassword}
        onChange={(e) => setEnteredPassword(e.target.value)}
      />
      <button type="submit" className="login-button">Login</button>
    </form>
  );
}


export default App;