import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
	// global vars
	:root {
		--primary: #fff;
		--secondary: #fec325;
		

		--header-height: 10vh;
		--header-background: #fff;

		--footer-height: 8vh;
		--footer-background: #fff;

		--navbar-width: 6vw;
		--navbar-background: grey;
		
		--breadcrumb-height: 4vh;
		--breadcrumb-background: none;
				
		--content-height: 76vh;
		--content-width: 90vw;
		--content-background: white;
		
		--tooltip-text-color: white;
		--tooltip-background-color: black;
		--tooltip-margin: 30px;
		--tooltip-arrow-size: 6px;
	}

	.logoff
	{
		position: absolute;
		top: 11%;
		left: 50%;
	}

	.login-container
	{
	    display: flex;
    	flex-direction: column;
    	justify-content: center;
    	align-items: center;
    	height: 100%;
    	width: 100%; 
	}

	.login-borders
	{
		background-color: rgba(255, 255, 255, 0.8);
		border-radius: 1em;
		padding: 1em;
		font-weight: bold;
	}

	.login-button
	{
		margin-left: .5em;
		border-radius: .5em;
		background-color: #acacac;
	}

	.login-input
	{
		margin-left: .5em;
	}

	body {
		font-family: 'Arial', sans-serif;
	}

	mask {
		background-size: cover;
		background-position-y: bottom;
		filter: grayscale(.4) brightness(0.8);
		z-index: 0;
		display: flex;
		justify-content: center;
		align-items: center; 
		height: 150%;
		width: 100%;
	}
	
	::-webkit-scrollbar {
			width: 10px;
			border-radius: 10px;
			transform: translateX(20px);
		}

		/* Track */
		::-webkit-scrollbar-track {
			background: #f1f1f1;
			margin-top: 10vh;
			margin-bottom: 2vh;
			border-radius: 5px;
		}

		/* Handle */
		::-webkit-scrollbar-thumb {
			background: #888;
			border-radius: 5px;
		}

		/* Handle on hover */
		::-webkit-scrollbar-thumb:hover {
			background: #555;
		}

		@media screen and (max-width: 480px) {

			mask {
				display: none;
			}	
			section {
				height: 200%;
				overflow: unset;
			}

		::-webkit-scrollbar {
			width: unset;
			border-radius: unset;
			transform: unset;
		}

		/* Track */
		::-webkit-scrollbar-track {
			background: unset;
			margin-top: unset;
			margin-bottom: unset;
			border-radius: unset;
		}

		/* Handle */
		::-webkit-scrollbar-thumb {
			background: unset;
			border-radius: unset;
		}

		/* Handle on hover */
		::-webkit-scrollbar-thumb:hover {
			background: unset;
		}
	}

	// tablesorter pager button effect
    .pager svg:hover {
		filter: drop-shadow(0px 1px 1px black);
		transform: scale(1.1);
		background: #d5d5d5;
		border-radius: 10px;
		cursor: pointer;
	}
`;

export default GlobalStyles;
